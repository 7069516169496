import React from "react";
import bigLine from "../assets/bigLine.png";
import bigLine2 from "../assets/bigLineMobile.png";
import bg_1 from "../assets/bg_1.png";
import bg_2 from "../assets/bg_2.png";
import bg_3 from "../assets/bg_3.png";
import bg_4 from "../assets/bg_4.png";
import fg_1 from "../assets/fg_1.png";
import fg_2 from "../assets/fg_2.png";
import fg_3 from "../assets/fg_3.png";
import fg_4 from "../assets/fg_4.png";

import bg_1_mobile from "../assets/bg_1_mobile.png";
import bg_2_mobile from "../assets/bg_2_mobile.png";
import bg_3_mobile from "../assets/bg_3_mobile.png";
import bg_4_mobile from "../assets/bg_4_mobile.png";
import fg_1_mobile from "../assets/fg_1_mobile.png";
import fg_2_mobile from "../assets/fg_2_mobile.png";
import fg_3_mobile from "../assets/fg_3_mobile.png";
import fg_4_mobile from "../assets/fg_4_mobile.png";

const Works = ({works}) => {
  
  // const works = [
  //   {
  //     bg1: bg_1,
  //     fg1: fg_1,
  //     bg2: bg_1_mobile,
  //     fg2: fg_1_mobile,
  //     title: " Initial Consultation & Planning",
  //     desc: "What Happens: We discuss your idea, goals, and define the key objectives of validation.",
  //   },
  //   {
  //     bg1: bg_2,
  //     fg1: fg_2,
  //     bg2: bg_2_mobile,
  //     fg2: fg_2_mobile,
  //     title: "Market Research & Feedback Collection",
  //     desc: "We conduct market research and gather insights from potential customers using surveys and interviews.",
  //   },

  //   {
  //     bg1: bg_3,
  //     fg1: fg_3,
  //     bg2: bg_3_mobile,
  //     fg2: fg_3_mobile,
  //     title: "Data Analysis & Recommendations",
  //     desc: "We analyze the feedback, identify key trends, and prepare actionable recommendations for your idea.",
  //   },
  //   {
  //     bg1: bg_4,
  //     fg1: fg_4,
  //     bg2: bg_4_mobile,
  //     fg2: fg_4_mobile,
  //     title: "Results & Next Steps Consultation",
  //     desc: "We present a detailed report, discuss the findings, and guide you on the next steps.",
  //   },
  // ];
  return (
    <div className="flex flex-col mt-14 !items-start w-[100%] md:w-[68%]">
      <h3 className=" font-cabin font-[500] text-xl md:text-3xl">
        How it works
      </h3>
      <div className="w-[100%] h-[1px] bg-[#263238] opacity-20 mt-4 mb-4"></div>

      <div className="rounded-3xl bg-white   flex flex-col h-[1141px] mx-auto  relative w-[100%] md:!hidden">
        {/* <img src={bigLine2}/> */}
        {works.map((item, index) => (
          <div className="relative mb-40 ml-3 " key={index}>
            {/* Background Image */}
            <img src={item.bg} className=" h-auto" alt="background" />

            {/* Foreground Image */}
            <img
              src={item.fg}
              className="absolute inset-4  h-auto"
              alt="foreground"
            />
          </div>
        ))}

        <div className="absolute ">
          <img src={bigLine2} alt="line" />
        </div>
        
      </div>

      <div className="rounded-3xl bg-white min-w-[100%] min-h-[471px]   md:flex relative hidden items-center justify-center px-4 ">
        {/* <img src={bigLine}/> */}

        {works.map((data, index) => (
          <div
            key={index}
            className={`flex max-w-[178px] gap-8   ${
              index % 2 === 0 ? "flex-col" : "flex-col-reverse"
            }`}
          >
            <div className="relative mb-3 -ml-1 p-3">
              {/* Background Image */}
              <img src={data.bg} className=" h-auto " alt="background" />

              {/* Foreground Image */}
              <img
                src={data.fg}
                className="absolute inset-3 h-auto"
                alt="foreground"
              />
            </div>

            <div className="flex flex-col gap-2 ml-1 mb-3 p-2">
              <p className="font-semibold text-[14px] text-gray-600">{data.step}</p>
              <p className="text-[10px] w-[140px] font-medium  text-gray-500 ">{data.description}</p>
            </div>
          </div>
        ))}

        <div className="absolute inset-x-5  inset-y-9 z-0">
          <img src={bigLine} className="min-h-[95%] min-w-[100%]" alt="line" />
        </div>
      </div>
    </div>
  );
};

export default Works;
