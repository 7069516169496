import React from "react";
import benefits_1 from "../assets/card_bg1.png";
import benefits_2 from "../assets/card_bg2.png";
import benefits_3 from "../assets/card_bg3.png";
import benefits_4 from "../assets/card_bg4.png";
import benefits_5 from "../assets/card_bg5.png";
import benefits_6 from "../assets/card_bg6.png";
import { useMediaQuery } from "@mui/material";
import Slider from "react-slick";
// Import Slick CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Benefits = ({benefits}) => {
  const {title} = benefits
  const isMobile = useMediaQuery("(max-width:640px)");
  const isTab = useMediaQuery("(max-width:900px)")
   const benefitsData= [
        {
          title:"Reduces Risk of Failure",
          desc:"42% Startups fails because they build something, which is not required in the market",
          index:"01"
        }
      ,
       {
           title:"Saves Time and Resources",
           desc:"Focus on ideas that work, saving effort and money on dead ends.",
           index:"02"
       },
       {
           title:"Provides Market Insights",
           desc:"Learn what your customers want and need, straight from the source.",
           index:"03"
       },
       {
           title:"Improves Product-Market Fit",
           desc:"Shape your idea to better meet market demand.",
           index:"04"
       },
       {
           title:"Increases Confidence in Decision-Making",
           desc:"Make choices backed by real data, not just guesses.",
           index:"05"
       },
       {
           title:"Helps Identify & Address Challenges",
           desc:"Catch issues early, so you can fix them before they grow.",
           index:"06"
       },

   ]

  const settings = {
    dots: true, // Enables dots for navigation
    arrows: false, // Disables navigation arrows
    speed: 500, // Transition speed (in ms)
    slidesToShow: isMobile ? 3 : 2, // Default: Show 3 slides at once for larger screens
    slidesToScroll: 1,
  };

  return (
    <div className="flex flex-col mt-12 md:mt-14 !items-start w-[100%] md:w-[68%]">
      <h3 className="font-cabin font-[500] text-xl md:text-3xl">
        {title}
      </h3>
      <div className="w-[100%] h-[1px] bg-[#263238] opacity-20 mt-4 mb-4"></div>

{/* Mobile screen */}

{/*Anshuman bhai's code
<div className="w-full md:hidden">
        <Slider {...settings}>
        <div className="flex flex-col h-[220px] w-[220px]">
              <img src={benefits_1} alt="benefit1" style={{zIndex:"1"}}/>
                <p style={{position:"relative",zIndex:"2",left:"5px",bottom:"110px",fontWeight:"500",width:"130px",fontSize:"11px"}}>{benefitsData[0].title}</p>
                <p style={{position:"relative",zIndex:"2",bottom:"105px",width:"140px",fontSize:"7px"}}>{benefitsData[0].desc}</p>
                <p style={{position:"relative",zIndex:"2",bottom:"94px",fontSize:"18px"}}>{benefitsData[0].index}</p>
          </div>

          <div className="flex flex-col h-[220px] w-[220px]">
              <img src={benefits_2} alt="benefit2" style={{zIndex:"1"}}/>
                <p style={{position:"relative",zIndex:"2",left:"5px",bottom:"110px",fontWeight:"500",width:"130px",fontSize:"11px"}}>{benefitsData[1].title}</p>
                <p style={{position:"relative",zIndex:"2",bottom:"110px",width:"140px",fontSize:"7px"}}>{benefitsData[1].desc}</p>
                <p style={{position:"relative",zIndex:"2",bottom:"100px",fontSize:"18px"}}>{benefitsData[1].index}</p>
          </div>

          <div className="flex flex-col h-[220px] w-[220px]">
              <img src={benefits_3} alt="benefit3" style={{zIndex:"1"}}/>
                <p style={{position:"relative",zIndex:"2",left:"5px",bottom:"110px",fontWeight:"500",width:"130px",fontSize:"11px"}}>{benefitsData[2].title}</p>
                <p style={{position:"relative",zIndex:"2",bottom:"105px",width:"140px",fontSize:"7px"}}>{benefitsData[2].desc}</p>
                <p style={{position:"relative",zIndex:"2",bottom:"85px",fontSize:"18px"}}>{benefitsData[2].index}</p>
          </div>

          <div className="flex flex-col h-[220px] w-[220px]">
              <img src={benefits_4} alt="benefit4" style={{zIndex:"1"}}/>
                <p style={{position:"relative",zIndex:"2",left:"5px",bottom:"110px",fontWeight:"500",width:"130px",fontSize:"11px"}}>{benefitsData[3].title}</p>
                <p style={{position:"relative",zIndex:"2",bottom:"110px",width:"140px",fontSize:"7px"}}>{benefitsData[3].desc}</p>
                <p style={{position:"relative",zIndex:"2",bottom:"100px",fontSize:"18px"}}>{benefitsData[3].index}</p>
          </div>

          <div className="flex flex-col h-[220px] w-[220px]">
              <img src={benefits_5} alt="benefit5" style={{zIndex:"1"}}/>
                <p style={{position:"relative",zIndex:"2",left:"5px",bottom:"110px",fontWeight:"500",width:"130px",fontSize:"11px"}}>{benefitsData[4].title}</p>
                <p style={{position:"relative",zIndex:"2",bottom:"110px",width:"140px",fontSize:"7px"}}>{benefitsData[4].desc}</p>
                <p style={{position:"relative",zIndex:"2",bottom:"100px",fontSize:"18px"}}>{benefitsData[4].index}</p>
          </div>

          <div className="flex flex-col h-[220px] w-[220px]">
              <img src={benefits_6} alt="benefit1" style={{zIndex:"1"}}/>
                <p style={{position:"relative",zIndex:"2",left:"5px",bottom:"110px",fontWeight:"500",width:"130px",fontSize:"11px"}}>{benefitsData[5].title}</p>
                <p style={{position:"relative",zIndex:"2",bottom:"110px",width:"140px",fontSize:"7px"}}>{benefitsData[5].desc}</p>
                <p style={{position:"relative",zIndex:"2",bottom:"100px",fontSize:"18px"}}>{benefitsData[5].index}</p>
          </div>

        </Slider>


*/}

      {/* My code */}
      <div className="w-full gap-2 h-[150px] overflow-x-scroll overflow-y-hidden scroll-smooth" style={{display:isMobile?"flex":"none"}}>
        <div className="flex flex-col h-[220px] w-[220px]">
              <img src={benefits_1} alt="benefit1" style={{zIndex:"1"}}/>
                <p style={{position:"relative",zIndex:"2",left:"2px",bottom:"110px",fontWeight:"500",width:"130px",fontSize:"11px"}}>{benefitsData[0].title}</p>
                <p style={{position:"relative",zIndex:"2",bottom:"105px",width:"140px",fontSize:"7px"}}>{benefitsData[0].desc}</p>
                <p style={{position:"relative",zIndex:"2",bottom:"94px",fontSize:"18px"}}>{benefitsData[0].index}</p>
          </div>

          <div className="flex flex-col h-[220px] w-[220px]">
              <img src={benefits_2} alt="benefit2" style={{zIndex:"1"}}/>
                <p style={{position:"relative",zIndex:"2",left:"2px",bottom:"110px",fontWeight:"500",width:"130px",fontSize:"11px"}}>{benefitsData[1].title}</p>
                <p style={{position:"relative",zIndex:"2",bottom:"110px",width:"140px",fontSize:"7px"}}>{benefitsData[1].desc}</p>
                <p style={{position:"relative",zIndex:"2",bottom:"100px",fontSize:"18px"}}>{benefitsData[1].index}</p>
          </div>

          <div className="flex flex-col h-[220px] w-[220px]">
              <img src={benefits_3} alt="benefit3" style={{zIndex:"1"}}/>
                <p style={{position:"relative",zIndex:"2",left:"2px",bottom:"110px",fontWeight:"500",width:"130px",fontSize:"11px"}}>{benefitsData[2].title}</p>
                <p style={{position:"relative",zIndex:"2",bottom:"105px",width:"140px",fontSize:"7px"}}>{benefitsData[2].desc}</p>
                <p style={{position:"relative",zIndex:"2",bottom:"85px",fontSize:"18px"}}>{benefitsData[2].index}</p>
          </div>

          <div className="flex flex-col h-[220px] w-[220px]">
              <img src={benefits_4} alt="benefit4" style={{zIndex:"1"}}/>
                <p style={{position:"relative",zIndex:"2",left:"2px",bottom:"110px",fontWeight:"500",width:"130px",fontSize:"11px"}}>{benefitsData[3].title}</p>
                <p style={{position:"relative",zIndex:"2",bottom:"110px",width:"140px",fontSize:"7px"}}>{benefitsData[3].desc}</p>
                <p style={{position:"relative",zIndex:"2",bottom:"100px",fontSize:"18px"}}>{benefitsData[3].index}</p>
          </div>

          <div className="flex flex-col h-[220px] w-[220px]">
              <img src={benefits_5} alt="benefit5" style={{zIndex:"1"}}/>
                <p style={{position:"relative",zIndex:"2",left:"2px",bottom:"110px",fontWeight:"500",width:"130px",fontSize:"11px"}}>{benefitsData[4].title}</p>
                <p style={{position:"relative",zIndex:"2",bottom:"110px",width:"140px",fontSize:"7px"}}>{benefitsData[4].desc}</p>
                <p style={{position:"relative",zIndex:"2",bottom:"100px",fontSize:"18px"}}>{benefitsData[4].index}</p>
          </div>

          <div className="flex flex-col h-[220px] w-[220px]">
              <img src={benefits_6} alt="benefit1" style={{zIndex:"1"}}/>
                <p style={{position:"relative",zIndex:"2",left:"2px",bottom:"110px",fontWeight:"500",width:"130px",fontSize:"11px"}}>{benefitsData[5].title}</p>
                <p style={{position:"relative",zIndex:"2",bottom:"110px",width:"140px",fontSize:"7px"}}>{benefitsData[5].desc}</p>
                <p style={{position:"relative",zIndex:"2",bottom:"100px",fontSize:"18px"}}>{benefitsData[5].index}</p>
          </div>
          </div>
        
        {/* <Swiper
                   modules={[Navigation, Pagination]} // Include Pagination in the modules
          spaceBetween={50}
          slidesPerView={2}
          pagination={{ clickable: true }} // Enable dots
          
          onSlideChange={() => console.log('slide change')}
          onSwiper={(swiper) => console.log(swiper)}
    >
         <SwiperSlide>
            <img src={benefits_1} className='w-[147px] h-[151px] md:w-[209px] md:h-[216px]' alt="benefit 1" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={benefits_2} className='w-[147px] h-[151px] md:w-[209px] md:h-[216px]' alt="benefit 2" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={benefits_3} className='w-[147px] h-[151px] md:w-[209px] md:h-[216px]' alt="benefit 3" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={benefits_4} className='w-[147px] h-[151px] md:w-[209px] md:h-[216px]' alt="benefit 4" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={benefits_5} className='w-[147px] h-[151px] md:w-[209px] md:h-[216px]' alt="benefit 5" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={benefits_6} className='w-[147px] h-[151px] md:w-[209px] md:h-[216px]' alt="benefit 6" />
          </SwiperSlide>
      
      </Swiper> */}

        {/* Desktop view */}
      <div className="sm:flex hidden gap-4 flex-wrap w-[750px]" style={{width:isTab?"550px":"750px"}}>
        {/* Benefit cards */}
          <div className="flex flex-col h-[220px] w-[220px]">
              <img src={benefits_1} alt="benefit1" style={{zIndex:"1"}}/>
                <p style={{position:"relative",zIndex:"2",bottom:"175px",fontWeight:"500",width:"200px"}}>{benefitsData[0].title}</p>
                <p style={{position:"relative",zIndex:"2",width:"200px",bottom:"165px",fontSize:"10px"}}>{benefitsData[0].desc}</p>
                <p style={{position:"relative",zIndex:"2",bottom:"135px",fontSize:"25px"}}>{benefitsData[0].index}</p>
          </div>

          <div className="flex flex-col h-[220px] w-[220px]">
              <img src={benefits_2} alt="benefit1" style={{zIndex:"1"}}/>
                <p style={{position:"relative",zIndex:"2",bottom:"175px",fontWeight:"500",width:"180px"}}>{benefitsData[1].title}</p>
                <p style={{position:"relative",zIndex:"2",width:"200px",bottom:"170px",fontSize:"10px"}}>{benefitsData[1].desc}</p>
                <p style={{position:"relative",zIndex:"2",bottom:"145px",fontSize:"25px"}}>{benefitsData[1].index}</p>
          </div>

          <div className="flex flex-col h-[220px] w-[220px]">
              <img src={benefits_3} alt="benefit1" style={{zIndex:"1"}}/>
                <p style={{position:"relative",zIndex:"2",bottom:"175px",fontWeight:"500",width:"180px"}}>{benefitsData[2].title}</p>
                <p style={{position:"relative",zIndex:"2",width:"200px",bottom:"170px",fontSize:"10px"}}>{benefitsData[2].desc}</p>
                <p style={{position:"relative",zIndex:"2",bottom:"145px",fontSize:"25px"}}>{benefitsData[2].index}</p>
          </div>

          <div className="flex flex-col h-[220px] w-[220px]">
              <img src={benefits_4} alt="benefit1" style={{zIndex:"1"}}/>
                <p style={{position:"relative",zIndex:"2",bottom:"175px",fontWeight:"500",width:"180px"}}>{benefitsData[3].title}</p>
                <p style={{position:"relative",zIndex:"2",width:"200px",bottom:"170px",fontSize:"10px"}}>{benefitsData[3].desc}</p>
                <p style={{position:"relative",zIndex:"2",bottom:"145px",fontSize:"25px"}}>{benefitsData[3].index}</p>
          </div>

          <div className="flex flex-col h-[220px] w-[220px]">
              <img src={benefits_5} alt="benefit1" style={{zIndex:"1"}}/>
                <p style={{position:"relative",zIndex:"2",bottom:"175px",fontWeight:"500",width:"190px"}}>{benefitsData[4].title}</p>
                <p style={{position:"relative",zIndex:"2",width:"200px",bottom:"170px",fontSize:"10px"}}>{benefitsData[4].desc}</p>
                <p style={{position:"relative",zIndex:"2",bottom:"145px",fontSize:"25px"}}>{benefitsData[4].index}</p>
          </div>

          <div className="flex flex-col h-[220px] w-[220px]">
              <img src={benefits_6} alt="benefit1" style={{zIndex:"1"}}/>
                <p style={{position:"relative",zIndex:"2",bottom:"175px",fontWeight:"500",width:"180px"}}>{benefitsData[5].title}</p>
                <p style={{position:"relative",zIndex:"2",width:"200px",bottom:"170px",fontSize:"10px"}}>{benefitsData[5].desc}</p>
                <p style={{position:"relative",zIndex:"2",bottom:"145px",fontSize:"25px"}}>{benefitsData[5].index}</p>
          </div>




        {/* <div className={`flex flex-col gap-1 w-[209px] h-[216px] rounded-bl-xl rounded-br-xl rounded-tl-[30%] items-center justify-end bg-[#F3F9FF]`}>

<p className='text-xl font-semibold'>Reduces Risk of Failure</p>
<p className='mb-9 text-[10px] font-medium'><span className='text-[#1C6ED0]'>42% Startups fails</span> because they build something, which is not required in the market.</p>
<div className='w-[100%] h-11 flex justify-end bg-[#9BC5EF]' ></div>
<div className={`bg-white rounded-[100%] p-1 w-[73px] h-[73px] absolute mb-2 text-3xl font-semibold flex justify-center`} style={{
    border:`3px solid #9BC5EF`
}} >01</div>

    </div> */}

        

        {/* {
        
        benefitsData.map((data,index)=>(
            <div className={`flex flex-col gap-1 w-[209px] h-[216px] rounded-bl-xl rounded-br-xl rounded-tl-[30%] items-center justify-end`} style={{
                backgroundColor:`${data.color1}`
            }}>

            <p className='text-xl font-semibold'>{data.title}</p>
            <p className='mb-9 text-[10px] font-medium'>{data.desc}</p>

        
         

           
       
<div className='w-[100%] h-11 flex justify-end' style={{
                backgroundColor:`${data.color2}`
            }}></div>

<div className={`bg-white rounded-[100%] p-1 w-[73px] h-[73px] absolute mb-2 text-3xl font-semibold flex justify-center`} style={{
                border:`3px solid  ${data.color2}`
            }} >{data.index}</div>

            </div>
            
        ))
    } */}
      </div>
    </div>
  );
};

export default Benefits;
