import React from "react";
import NavBar from "../Header/NavBar";
import Bottom from "./components/Bottom";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";
import ServicePage from "../../pages/ServicePage";

const selfService = () => {
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>
          Startup Idea Validation Service | Validate Your Idea with Experts |
          Bizowl
        </title>
        <meta
          name="description"
          content="Validate your startup idea with Bizowl's expert service. Get market validation, competitor analysis, target audience feedback, and personalized expert guidance to ensure your business idea stands out."
        />
        <meta
          name="keywords"
          content="Startup Idea Validation, Validate Your Startup Idea, How to validate a startup idea, How to validate an idea for a startup, Market validation for startup, Startup market validation, Startup idea validation framework, Idea validation for startups, Idea validation in entrepreneurship, Validating a startup idea, Business idea validation tool"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Bizowl" />
        <meta http-equiv="Content-Language" content="en" />
        <link
          rel="canonical"
          href="https://bizzowl.com/services/startup-idea-validation-service "
        />
        <meta
          property="og:title"
          content="Startup Idea Validation Service | Validate Your Idea with Experts | Bizowl"
        />
        <meta
          property="og:description"
          content="Bizowl's Startup Idea Validation service helps you validate your business idea with market research, competitor analysis, expert feedback, and more."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dcduojrhf/image/upload/v1727166128/e5phmrz40wxe2rrpaug9.png"
        />
        <meta
          property="og:url"
          content="https://bizzowl.com/services/startup-idea-validation-service"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Startup Idea Validation Service | Validate Your Idea with Experts | Bizowl"
        />
        <meta
          name="twitter:description"
          content="Ensure your business idea is viable with Bizowl's Startup Idea Validation service, including market research, expert feedback, and more."
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/dcduojrhf/image/upload/v1727166128/e5phmrz40wxe2rrpaug9.png "
        />
        <script type="application/ld+json">
          {`
  "@context": "https://schema.org",
  "@type": "Product",
  "name": "Startup Idea Validation Service",
  "description": "Bizowl's Startup Idea Validation service helps aspiring founders validate their business ideas with market research, competitive analysis, target audience feedback, expert advice, and more.",
  "brand": {
    "@type": "Organization",
    "name": "Bizowl",
    "sameAs": "https://bizzowl.com",
    "logo": "https://www.bizzowl.com/Images/logos/logo-of-BIZOWL--Business-Services.png"
  },
  "offers": {
    "@type": "Offer",
    "url": "https://bizzowl.com/services/startup-idea-validation-service",
    "priceCurrency": "INR",
    "price": "6149",
    "priceValidUntil": "2025-12-31",
    "availability": "https://schema.org/InStock",
    "itemCondition": "https://schema.org/NewCondition"
  },
  "review": {
    "@type": "Review",
    "reviewRating": {
      "@type": "Rating",
      "ratingValue": "4.8",
      "bestRating": "5"
    },
    "author": {
      "@type": "Person",
      "name": "Prashant Sirohi"
    }
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.7",
    "reviewCount": "38"
  },
  "category": "Business Idea Validation"
`}
        </script>
      </Helmet>
      <div className="bg-[#F5F5F5] w-[100vw] overflow-x-hidden ">
        <NavBar />
        <div className=" md:!p-16 p-3 pt-0  w-[100%] md:w-[80%] mx-auto bg-[#F5F5F5]  mb-2">
          <ServicePage />
        </div>
        <Footer />
        <Bottom />
      </div>
    </>
  );
};

export default selfService;
