import React from "react";
import logo from "../assets/logo.png";
import img_1 from "../assets/img_1.png";
import img_2 from "../assets/img_2.png";
import img_3 from "../assets/img_3.png";
import img_4 from "../assets/img_4.png";
import img_5 from "../assets/img_5.png";
import img_6 from "../assets/img_6.png";
import arrow from "../assets/arrow.png";
import { useNavigate } from "react-router-dom";

const About = ({ aboutService, features, serviceName, payemnetSummary }) => {
  const { content1, content2 } = aboutService;
  const { totalPayment, gst, Payable } = payemnetSummary;
  const navigate = useNavigate();
  return (
    <div className="flex gap-14 !items-start !justify-start">
      {/* left side */}
      <div className="flex  flex-col-reverse md:flex-col md:mt-14 !items-start w-[100%] md:w-[68%] text-left">
        <div className="flex flex-col !items-start w-[100%] ">
          <h3 className="font-cabin font-[500] text-xl md:text-3xl mt-12 md:mt-0">
            About the Service
          </h3>
          <div className="w-[100%] h-[1px] bg-[#263238] opacity-20 mt-4 mb-4"></div>
          <div className="md:hidden">
            <p className="text-sm ">{content1}</p>
            <div className="justify-start flex gap-1">
              <p className="text-[#0F88CA]">Show More</p>
              <img src={arrow} alt="arrow" />
            </div>
          </div>

          <p className="md:text-lg hidden md:block">{content1}</p>
          <br />

          <p className="md:text-lg hidden md:block">{content2}</p>
        </div>

        <div className="">
          <div className="w-[100%] h-[1px] bg-[#263238] opacity-20 mt-[20px] md:mt-[93px] mb-4"></div>

          <div className="font-cabin">
            {features.map((data, index) => (
              <div
                key={index}
                className="flex gap-3 mt-4 md:mt-11 !items-start"
              >
                <img
                  src={data.icon}
                  alt="img"
                  className="w-[36px] h-[36px] md:w-[50px] md:h-[50px] "
                />

                <div className="flex flex-col !items-start ">
                  <p className="text-lg md:text-2xl font-[500]">{data.title}</p>
                  <p className="text-sm md:text-lg text-[#263238] opacity-70">
                    {data.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* right side */}
      <div className="hidden md:flex md:flex-col mt-[5rem] !items-start w-[33%]  text-left shadow-[0px_6px_6px_rgba(69,90,100,0.25)] rounded-[30px] mt-">
        <div className=" w-[100%] rounded-[30px] bg-white ">
          <div className="bg-[#D7E9FD] pt-3 pb-1 px-3 text-center rounded-t-3xl ">
            <p className="text-[#1B2559] text-[22px] font-cabin font-semibold">
              Payment Summary
            </p>
          </div>
          <div className="p-6 flex flex-col gap-3 !items-start">
            <div className="mx-auto">
              <img src={logo} alt="img" />
            </div>

            <div>
              <h2 className="text-[22px] font-semibold font-cabin opacity-80 text-[#1B2559]">
                {serviceName}
              </h2>
            </div>

            <div className="w-[100%]">
              <div className="flex !justify-between">
                <p className="text-[#1B2559] text-[18px] font-cabin ">
                  Total Payment
                </p>
                <p className="font-bold text-[22px] font-cabin">₹{totalPayment} /-</p>
              </div>
              <div className="flex !justify-between">
                <p className="text-[#1B2559] text-[18px] font-cabin ">
                  GST ( 18% )
                </p>
                <p className="font-bold text-[22px] font-cabin">₹{gst} /-</p>
              </div>
            </div>
          </div>

          <div className="w-[100%] h-[1px] bg-[#263238] opacity-20 mt-4 mb-4"></div>

          <div className="flex !justify-between px-6 pb-6">
            <p className="text-[#1B2559] font-bold text-[22px] font-cabin ">
              Payable Amount
            </p>
            <p className="font-bold text-[22px] font-cabin">₹{Payable} /-</p>
          </div>
          <div className="flex items-center justify-center">
            <button
              className="mx-auto bg-[#407BFF] rounded-3xl py-2 px-2 font-bold text-2xl text-white font-cabin w-[60%] mb-2"
              onClick={() => navigate("/booking-self")}
            >
              Validate Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
