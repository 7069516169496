import Launch from "./assets/launch.png";
import Scale from "./assets/scale.png";
import Learn from "./assets/learn.png";
import Get from "./assets/get.png";
import Services from "./assets/serviceGroup.png";
import AiTools from "./assets/aiTools.png";
import Resources from "./assets/resourcesSection.png";
import Guidance from "./assets/guidanceSection.png";
import Circ from "./assets/circ.png";
import { useMediaQuery } from "@mui/material";

const HeroSection = () => {

  const isMobile = useMediaQuery("(max-width:768px)");
  return (
    <div className="flex flex-wrap bg-gradient-to-r from-blue-100 pb-52 w-screen " style={{paddingBottom: isMobile && "5rem", paddingInline:!isMobile&&"4%", paddingLeft:isMobile?"5px":"80px",}}>
      <div className="font-cabin flex flex-wrap lg:flex-nowrap pt-[1.5rem] pl-[5px] pr-10 md:px-20 lg:px-0 xl:px-10 md:py-10">
        <div
          style={{ textAlign: "start" }}
          className="ml-3 mt-6 w-full lg:w-[540px] xl:w-[580px] "
        >
          {/* <h1 className="inline text-2xl  md:text-[2.5rem] font-semi-bold md:leading-[1.5]" style={{display: "flex", flexDirection: "column", justifyContent: "left"}}>
            Get Everythings to Start{" "}

  let isTab=useMediaQuery('(max-width:1023px)');
  let isSmallMobile=useMediaQuery('(max-width:450px)')
  let isMobile=useMediaQuery('(max-width:589px)')
  return (
    <div className="flex flex-wrap bg-gradient-to-r from-blue-100 pb-52 w-screen">
      <div className="font-cabin flex flex-wrap lg:flex-nowrap p-[0.5rem] sm:px-5 sm:py-5 md:px-20 lg:px-0 xl:px-20 md:py-10">
        <div className="ml-3 mt-6 w-full lg:w-[560px] xl:w-[580px] text-left">
          <h1 className="inline text-2xl  sm:text-[2.5rem] font-bold md:leading-[1.5]">
            Get Everything to Start{" "}

            <span className="text-blue-600">and Grow Your</span>
            &nbsp; Startup
          </h1> */}
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" , fontFamily:"cabin",width:isMobile?"":"90%"}}>
            <h1
              style={{
                fontSize: isMobile ? "25px" : "35px",
                lineHeight: 1.3,
                textAlign: "left",
                fontWeight: "550",
                width:isMobile?"340px":"450px"
              }}
            >
              Get Everything to Start{" "}
              <span className="text-blue-600"> And Grow Your </span>Startup{" "}
              <span>
                <div
                  className="inline-block"
                  style={{ marginLeft: "-0.4rem", marginBottom: "-0.75rem" }}
                >
                  <div className="relative inline-block   bg-[#1C6ED0] w-[20px] h-[20px] md:w-[30px] md:h-[30px] rounded-[50%] left-[20%]"></div>
                  <div className="inline-block bg-black w-[20px] h-[20px] md:w-[30px] md:h-[30px] rounded-[50%]"></div>
                </div>
              </span>
            </h1>
          </div>

          <div
            style={{}}
          >
            <p className=" md:font-[400] text-black opacity-80 text-left " style={{
              fontFamily: "'Inter', sans-serif",
              margin:isMobile? "20px 0px": "17px 0px",
              fontSize:isMobile?"13px":"15px",
              width:isMobile?"340px":"450px"
            }}>
              Speak with experts to get guidance for your business. Get support
              for any stage of business.
            </p>
            <div style={{ width: isMobile ? "320px" : "540px", fontSize: isMobile && "0.8rem" , fontFamily:  "'Inter', sans-serif",fontWeight:"100"}}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: isMobile?"300px":"500px",
                  justifyContent: "space-between",
                }}
              >
                <p
                  style={{
                    fontWeight:"500",
                    fontSize:"14px",
                    width: isMobile ? "50%" : "40%",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: isMobile ? "2px" : "4px",
                    paddingRight: isMobile ? "2px" : "4px",
                    borderRadius: "50px",
                    border: "1px solid rgba(69, 90, 100, 0.3)",
                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: isMobile?"5px":"10px",
                    transform: "scale(1)",
                    transition: "transform 0.3s",
                    margin: "10px 10px 10px 0px",
                    

                  }}
                >
                  <img
                    style={{
                      width: isMobile ? "30px" : "40px",
                      height: isMobile ? "30px" : "40px",
                    }}
                    src={Launch}
                    alt="img"
                  />{" "}
                  Launch Business
                </p>
                <p
                  style={{
                    fontWeight:"500",
                    fontSize:"14px",
                    width: isMobile ? "50%" : "40%",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: isMobile ? "2px" : "4px",
                    paddingRight: isMobile ? "2px" : "4px",
                    borderRadius: "50px",
                    border: "1px solid rgba(69, 90, 100, 0.3)",
                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: isMobile?"5px":"10px",
                    transform: "scale(1)",
                    transition: "transform 0.3s",
                    margin: isMobile ? "10px 10px 10px 0px" : "10px 10px 10px 0px"
                  }}
                >
                  <img
                    style={{
                      width: isMobile ? "30px" : "40px",
                      height: isMobile ? "30px" : "40px",
                    }}
                    src={Scale}
                    alt="img"
                  />
                  Scale Startup
                </p>
              </div>
              <div
                style={{
                  width: isMobile?"300px":"500px",
                  display: "flex",
                  flexDirection: "row",
                  marginTop: isMobile ? "10px" : "0px",
                  justifyContent: "space-between"
                }}
              >
                <p
                  style={{
                    fontWeight:"500",
                    fontSize:"14px",
                    width: isMobile ? "50%" : "40%",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: isMobile ? "2px" : "4px",
                    paddingRight: isMobile ? "2px" : "4px",
                    borderRadius: "50px",
                    border: "1px solid rgba(69, 90, 100, 0.3)",
                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: isMobile?"5px":"10px",
                    transform: "scale(1)",
                    transition: "transform 0.3s",
                    margin: "10px 10px 10px 0px"
                  }}
                >
                  <img
                    style={{
                      width: isMobile ? "30px" : "40px",
                      height: isMobile ? "30px" : "40px",
                    }}
                    src={Learn}
                    alt="img"
                  />
                  Learn & Grow
                </p>
                <p
                  style={{
                    fontWeight:"500",
                    fontSize:"14px",
                    width: isMobile ? "50%" : "40%",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: isMobile ? "2px" : "4px",
                    paddingRight: isMobile ? "2px" : "4px",
                    borderRadius: "50px",
                    border: "1px solid rgba(69, 90, 100, 0.3)",
                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: isMobile?"5px":"10px",
                    transform: "scale(1)",
                    transition: "transform 0.3s",
                    margin: isMobile ? "10px 10px 10px 0px" : "10px 10px 10px 0px"
                  }}
                >
                  <img
                    style={{
                      width: isMobile ? "30px" : "40px",
                      height: isMobile ? "30px" : "40px",
                    }}
                    src={Get}
                    alt="img"
                  />
                  Get Support
                </p>
              </div>
            </div>
          </div>
          {/* <div style={{marginTop: isMobile && "-30px"}} className="text-sm md:text-lg md:w-[70%]">
            <p className="my-5 md:text-xl md:font-thin opacity-80 text-left">
              Speak with experts to get guidance for you business. Get support
              for any stage of business.
            </p>
            <div style={{display: isMobile && "flex", flexDirection: isMobile && "row"}} className=" my-4 md:my-6">
              <p style={{paddingTop: "5px", paddingBottom: "5px", paddingLeft: "40px !important", paddingRight: "40px !important"}} className=" text-[#7897BA] border border-[#455A64]   px-3 md:px-8 rounded-full bg-opacity-40 bg-white inline-block transform transition-transform duration-300 hover:scale-110">
                <img
                  className="inline-block  py-1 md:-ml-1 md:mr-1 w-[30px] h-[26px] md:w-[40pxp] md:h-[40px]"
                  src={Launch}
                  alt="img"
                />{" "}
                Launch Business
              </p>
              <p style={{paddingTop: "5px", paddingBottom: "5px", paddingLeft: "2px", paddingRight: "2px"}} className="ml-10 text-[#FFC741] border border-[#455A64]   px-3 md:px-4 rounded-full bg-opacity-40 bg-white inline-block transform transition-transform duration-300 hover:scale-110">
                <img
                  className="inline-block py-1 md:-ml-1 md:mr-1 w-[30px] h-[20px] md:w-[40pxp] md:h-[40px]"
                  src={Scale}
                  alt="img"
                />
                Scale Startup
              </p>
            </div>
            <div style={{display: isMobile && "flex", flexDirection: isMobile && "row"}}>
              <p style={{paddingTop: "5px", paddingBottom: "5px", paddingLeft: "2px", paddingRight: "2px"}} className=" text-[#CF9DBB] border border-[#455A64]  px-3 md:px-4 rounded-full bg-opacity-40 bg-white inline-block transform transition-transform duration-300 hover:scale-110">
                <img
                  style={{width: isMobile && "30px", height: isMobile && "30px"}}
                  className="inline-block py-1 md:-ml-1 md:mr-1 w-[30px] h-[26px] md:w-[40pxp] md:h-[40px]"
                  src={Learn}
                  alt="img"
                />
                Learn & Grow
              </p>
              <p style={{paddingTop: "5px", paddingBottom: "5px", paddingLeft: "2px", paddingRight: "2px"}} className="ml-16 text-[#A1D4C9] border border-[#455A64]  px-3 md:px-4 rounded-full bg-opacity-40 bg-white inline-block transform transition-transform duration-300 hover:scale-110">
                <img
                  className="inline-block py-1 md:-ml-1 md:mr-1 w-[30px] h-[26px] md:w-[40pxp] md:h-[40px]"
                  src={Get}
                  alt="img"
                />
                Get Support
              </p>
            </div>
          </div> */}

          {/* Features section */}
          {/* <div className="flex flex-wrap h-auto w-3/4 mb-5 mt-5" style={{width:isMobile&&"100%"||isTab&&"65%"||"75%"}}>
            <div className="flex items-center h-12 bg-yellow-50 rounded-3xl cursor-pointer hover:scale-105 transition-all text-[#7897BA] shadow" style={{width:isSmallMobile&&"140px"||"176px",fontSize:isTab&&"12px"||"14px",marginRight:"10px"}}><img src={Launch} className="h-8 w-8 ml-2 mr-2"/><p>Launch Business</p></div>
                <div className="flex items-center h-12 bg-yellow-50 rounded-3xl cursor-pointer hover:scale-105 transition-all text-[#FFC741] shadow" style={{width:isSmallMobile&&"140px"||"176px",fontSize:isTab&&"12px"||"14px",marginRight:isTab&&"0px"||"10px"}}><img src={Scale} className="h-8 w-8 ml-2 mr-2"/><p>Scale Startup</p></div>
                <div className="flex items-center h-12 bg-yellow-50 rounded-3xl mt-4 cursor-pointer hover:scale-105 transition-all text-[#CF9DBB] shadow" style={{width:isSmallMobile&&"140px"||"176px",fontSize:isTab&&"12px"||"14px",marginRight:"10px"}}><img src={Learn} className="h-8 w-8 ml-2 mr-2"/><p>Learn & Grow</p></div>
                <div className="flex items-center h-12 bg-yellow-50 rounded-3xl mt-4 cursor-pointer hover:scale-105 transition-all text-[#A1D4C9] shadow" style={{width:isSmallMobile&&"140px"||"176px",fontSize:isTab&&"12px"||"14px",marginRight:isTab&&"0px"||"10px"}}><img src={Get} className="h-8 w-8 ml-2 mr-2"/><p>Get Support</p></div>
            </div> */}

        </div>
      </div>

      {/* ############ Images Section ############# */}

      <div
        style={{ padding: "70px 0px 10px 0px" }}
        className="relative m-auto mt-4 lg:m-14"
      >
        <img
          src={Circ}
          alt="img"
          className="absolute -z-10 -right-[22%] -top-[22%] w-[165px] h-[165px] md:w-[345px] md:h-[345px] lg:w-[206px] lg:h-[206px] xl:w-[345px] xl:h-[345px]  "
        />
        <img
          src={Circ}
          alt="img"
          className="absolute -z-10  -left-[25%] -bottom-[25%] lg:-bottom-[17%] xl:-bottom-[25%] w-[165px] h-[165px] md:w-[345px] md:h-[313px] lg:w-[206px] lg:h-[206px] xl:w-[345px] xl:h-[313px]"
        />
        <div>
          <div className="absolute -right-[8%] top-[23%] w-3 h-3 md:w-7 md:h-7 bg-[#407BFF] rounded-full" style={{top: isMobile && "10%", right: isMobile && "4%"}}></div>
          <div className="absolute -right-[4%] top-[5%] w-2 h-2 md:w-4 md:h-4 bg-[#FFC741] rounded-full" style={{top: isMobile && "5%", right: isMobile && "10%"}}></div>
          <div className="  absolute -right-[12%] top-[12%] w-1 h-1 md:w-2 md:h-2 bg-[#407BFF] rounded-full" style={{right: isMobile && "11%", top: isMobile && "10%"}}></div>
          <div className="hidden md:block  absolute -left-[17%] top-[55%] w-3 h-3 md:w-7 md:h-7 bg-[#407BFF] rounded-full"></div>
          <div className="hidden md:block  absolute -left-[9%] top-[45%] w-2 h-2 md:w-4 md:h-4 bg-[#FFC741] rounded-full"></div>
          <div className="hidden md:block absolute z-10 left-[0%] top-[50%] w-1 h-1 md:w-2 md:h-2 bg-[#407BFF] rounded-full"></div>
          <div className="absolute right-[45%] -bottom-[9%] w-2 h-2 md:w-4 md:h-4 bg-[#FFC741] rounded-full" style={{bottom: isMobile && "-3%", right: isMobile && "10%"}}></div>
          <div className="absolute right-[55%] -bottom-[15%] w-1 h-1 md:w-2 md:h-2 bg-[#407BFF] rounded-full"></div>
          <div></div>
          <div></div>
          <div></div>
          <img
            className="w-[143] h-[128px] md:w-[271px] md:h-[258px] lg:w-[161px] lg:h-[161px] xl:w-[271px] xl:h-[258px] inline-block transform transition-transform duration-300 hover:scale-110"
            style={{height: isMobile && "165px"}}
            src={Services}
            alt="img"
          />

          <img
            className="w-[143] h-[128px] md:w-[271px] md:h-[258px] lg:w-[161px] lg:h-[161px] xl:w-[271px] xl:h-[258px] inline-block transform transition-transform duration-300 hover:scale-110"
            style={{height: isMobile && "165px"}}
            src={AiTools}
            alt="img"
          />
        </div>

        <div>
          <img
            className="w-[143] h-[128px] md:w-[271px] md:h-[258px] lg:w-[161px] lg:h-[161px] xl:w-[271px] xl:h-[258px] inline-block transform transition-transform duration-300 hover:scale-110"
            style={{height: isMobile && "165px"}}
            src={Resources}
            alt="img"
          />

          <img
            className="w-[143] h-[128px] md:w-[271px] md:h-[258px] lg:w-[161px] lg:h-[161px] xl:w-[271px] xl:h-[258px] inline-block transform transition-transform duration-300 hover:scale-110"
            style={{height: isMobile && "165px"}}
            src={Guidance}
            alt="img"
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
