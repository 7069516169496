export const IdeaValidationObj = {
  serviceName: "Idea Validation",
  icon: "/Images/selfService/IdeaValidationImages/IdeaValIcon.png",
  heroSection: {
    image: "/Images/selfService/IdeaValidationImages/IdeaValHeroImg.png",
    message: "Because Market Fit is Everything",
    heading: "Avoid Failure: Validate Your ",
    headingPortion: "Idea Now",
    highlightedText: "42% of startups fail due to no market need.",
    content:
      "The biggest mistake entrepreneurs make is skipping idea validation. This crucial step ensures your idea solves real problems and meets market demands before you invest valuable time and money.",
  },
  aboutService: {
    content1:
      "Idea validation is like a reality check for your business idea. Before you dive in and spend time, money, and energy, you want to make sure there's actually a need for what you're planning to offer.",
    content2:
      "It's about testing your idea with real people to see if they're interested and willing to pay for it. Think of it as a way to ensure you're not building something that no one wants.",
  },
  features: [
    {
      icon: "/Images/selfService/IdeaValidationImages/IdeaValFeatures1/market-research.png",

      title: "Market Research",
      description:
        "Analysis of current trends,target audience needs,and competitor positioning.",
    },
    {
      icon: "/Images/selfService/IdeaValidationImages/IdeaValFeatures1/advantage.png",
      title: "Competitive Landscape Evaluation",
      description:
        "A clear view of your competitors' strengths and weaknesses, so you can position your idea uniquely in the market.",
    },
    {
      icon: "/Images/selfService/IdeaValidationImages/IdeaValFeatures1/target.png",
      title: "Target Audience Validation",
      description:
        "Direct feedback from your target audience to ensure your idea resonates and addresses their specific needs.",
    },
    {
      icon: "/Images/selfService/IdeaValidationImages/IdeaValFeatures1/video-conference.png",
      title: "1:1 Video Call",
      description:
        "Unlimited Personalized sessions with experts to discuss your idea, clarify doubts, and receive tailored advice.",
    },
    {
      icon: "/Images/selfService/IdeaValidationImages/IdeaValFeatures1/report.png",
      title: "Actionable Validation Report",
      description:
        "A detailed report with practical recommendations, clear insights, and steps to improve or pivot your idea.",
    },
    {
      icon: "/Images/selfService/IdeaValidationImages/IdeaValFeatures1/data-collection.png",
      title: "Post Validation Support",
      description:
        "Continued guidance after validation, helping you implement recommendations and plan the next steps confidently.",
    },
  ],
  payemnetSummary: {
    totalPayment: "5211",
    gst: "937",
    Payable: "6149",
  },
  benefits: {
    title: "Benefits of Idea Validation Service",
    data: [
      {
        title: "Reduces Risk of Failure",
        description:
          "42% Startup fails because they build something,which is not required in the market.",
        sno: "01",
      },
      {
        title: "Saves Time and Resources",
        description:
          "Focus on ideas that work,saving effort and money on dead ends.",
        sno: "02",
      },
      {
        title: "Provides Market Insights",
        description:
          "Learn what your customers want and need,straight from the source.",
        sno: "03",
      },
      {
        title: "Improves Product-Market Fit",
        description: "Shape your idea to better meet market demand.",
        sno: "04",
      },
      {
        title: "Increases Confidence in Decision-Making",
        description: "Make choices backed by real data, not just guesses.",
        sno: "05",
      },
      {
        title: "Helps Identify and Address Challenges",
        description: "Catch issues early,so you can fix them before they grow.",
        sno: "06",
      },
    ],
  },
  featuresOverview: [
    {
      title: "Dedicated Account Manager",
      icon: "/Images/selfService/IdeaValidationImages/IdeaValFeatures2/hugeicons_resources-add.png",
    },
    {
      title: "Future Strategy Support",
      icon: "/Images/selfService/IdeaValidationImages/IdeaValFeatures2/material-symbols_strategy-outline.png",
    },
    {
      title: "Expert-led Validation",
      icon: "/Images/selfService/IdeaValidationImages/IdeaValFeatures2/grommet-icons_user-expert.png",
    },
    {
      title: "Idea Pivot Support",
      icon: "/Images/selfService/IdeaValidationImages/IdeaValFeatures2/icons8_idea.png",
    },
    {
      title: "Data-Driven Insights",
      icon: "/Images/selfService/IdeaValidationImages/IdeaValFeatures2/material-symbols_search-insights.png",
    },
    {
      title: "Free Market Entry Consultation",
      icon: "/Images/selfService/IdeaValidationImages/IdeaValFeatures2/icon-park_market-analysis.png",
    },
    {
      title: "Risk Assessment and Mitigation",
      icon: "/Images/selfService/IdeaValidationImages/IdeaValFeatures2/ph_asterisk-fill.png",
    },
    {
      title: "Interactive Feedback Sessions",
      icon: "/Images/selfService/IdeaValidationImages/IdeaValFeatures2/codicon_feedback.png",
    },
    {
      title: "Access to a Resource Library",
      icon: "/Images/selfService/IdeaValidationImages/IdeaValFeatures2/hugeicons_resources-add.png",
    },
    {
      title: "Multi-Channel Feedback",
      icon: "/Images/selfService/IdeaValidationImages/IdeaValFeatures2/fluent_channel-24-regular.png",
    },
  ],

  howItWorks: [
    {
      bg:"/Images/selfService/IdeaValidationImages/IdeaValWorks/fg1.png",
      fg:"/Images/selfService/IdeaValidationImages/IdeaValWorks/bg1.png",
      step: "Initial Consultation & Planning",
      description:
        "We discuss your idea,goals,and definethe key objectives of validation.",
    },
    {
      bg:"/Images/selfService/IdeaValidationImages/IdeaValWorks/fg2.png",
      fg:"/Images/selfService/IdeaValidationImages/IdeaValWorks/bg2.png",
      step: "Market Research & Feedback Collection",
      description:
        "We conduct market research and gather insights from potential customers using surveys and interviews.",
    },
    {
      bg:"/Images/selfService/IdeaValidationImages/IdeaValWorks/fg3.png",
      fg:"/Images/selfService/IdeaValidationImages/IdeaValWorks/bg3.png",
      step: "Data Analysis & Recommendations",
      description:
        "We analyze the feedback, identify key trends, and prepare actionable recommendations for your idea.",
    },
    {
      bg:"/Images/selfService/IdeaValidationImages/IdeaValWorks/fg4.png",
      fg:"/Images/selfService/IdeaValidationImages/IdeaValWorks/bg4.png",
      step: "Results & Next Steps Consultation",
      description:
        "We present a detailed report. discuss the findings, and guide you on the next steps.",
    },
  ],
  targetAudience: [
    {
      icon: "/Images/servicePage/targeticon1.png",
      iconMobile: "/Images/servicePage/targeticonMobile1.png",
      title: "Aspiring Entrepreneurs",
      content1: "You're likely facing uncertainity and fear of failure",
      content2:
      "Our service helps you validate your idea, ensuring it meets a real market need,so you can avoid common startup mistakes.",
    },
    {
      icon: "/Images/servicePage/targeticon2.png",
      iconMobile: "/Images/servicePage/targeticonMobile2.png",
      title: "Small and Medium Businesses",
      content1: "Expanding into new markets can be risky",
      content2:
      "Our service lets you test new products or services with different demographics, reducing the uncertainity and risk of market expansion.",
    },
    {
      icon: "/Images/servicePage/targeticon3.png",
      iconMobile: "/Images/servicePage/targeticonMobile3.png",
      title: "Early-Stage Startups",
      content1:
      "With limited resources and high stakes,you need to prove your concept.",
      content2:
      "Our service helps you validate market demand ,gain traction, and improve your chances of securing funding.",
    },
    {
      icon: "/Images/servicePage/targeticon4.png",
      iconMobile: "/Images/servicePage/targeticonMobile4.png",
      title: "Product Managers",
      content1: "You're under pressure to innovate with minimal risk.",
      content2:
        "Use our service to validate new ideas or features, making sure they align with customer needs before investing resources.",
    },
  ],

  faq: [
    {
      que: "How can this service help me with my idea?",
      ans: "Our service helps you test your idea with real potential customers, gather their feedback, and see if there’s a genuine interest. This way, you can make informed decisions about moving forward with your idea.",
    },
    {
      que: "How long does the validation process take?",
      ans: "The timeline varies depending on the complexity of your idea and the methods we use, but typically it takes a few weeks to gather and analyze feedback.",
    },
    {
      que: "Can you guarantee that my idea will be successful?",
      ans: "While we can’t guarantee success, our service significantly increases your chances by providing you with valuable insights and data. We help you make better decisions based on real feedback.",
    },
    {
      que: "What if I don’t get the feedback I was hoping for?",
      ans: "If the feedback isn’t what you expected, it’s an opportunity to understand what might need to change. We’ll help you analyze the results and suggest ways to improve or pivot your idea.",
    },
    {
      que: "How do I know if the people giving feedback are the right audience?",
      ans: "We target feedback from people who match your ideal customer profile. This ensures that the insights you receive are relevant and useful for your specific market.",
    },
    {
      que: "What  happens if I need further assistance after validation?",
      ans: "We’re here to help! After validation, you can choose additional services for refining your idea, developing your product, or creating a go-to-market strategy. Just let us know what you need, and we’ll be happy to assist.",
    },
    {
      que: "How do you protect the privacy of my business idea and sensitive information?",
      ans: "We take confidentiality very seriously. All your information is securely handled, and we use non-disclosure agreements (NDAs) to protect your idea and sensitive data.",
    },
  ],
};