export const serviceData = [
  {
    serviceHead: "Business Planning & Strategy",
    imageData: [
      {
        Service: "Idea Validation",
        img: "/Images/servicePage/ourService/businessPlaning/icon1.png",
        route: "/services/startup-idea-validation-service"
      },
      {
        Service: "Business Model Consulting",
        img: "/Images/servicePage/ourService/businessPlaning/icon2.png",
      },
      {
        Service: "Pricing Model",
        img: "/Images/servicePage/ourService/businessPlaning/icon3.png",
      },
      {
        Service: "SWOT Analysis",
        img: "/Images/servicePage/ourService/businessPlaning/icon4.png",
      },
      {
        Service: "Business Canvas Model",
        img: "/Images/servicePage/ourService/businessPlaning/icon5.png",
      },
      {
        Service: "Market Entry Strategy",
        img: "/Images/servicePage/ourService/businessPlaning/icon6.png",
      },
      {
        Service: "Branding Proposition",
        img: "/Images/servicePage/ourService/businessPlaning/icon7.png",
      },
      {
        Service: "Business Feasibility",
        img: "/Images/servicePage/ourService/businessPlaning/icon8.png",
      },
      {
        Service: "Profitability Analysis",
        img: "/Images/servicePage/ourService/businessPlaning/icon9.png",
      },
      {
        Service: "Revenue Model",
        img: "/Images/servicePage/ourService/businessPlaning/icon10.png",
      },
    ],
  },
  {
    serviceHead: "Marketing",
    imageData: [
      {
        Service: "Seo",
        img: "/Images/servicePage/ourService/marketing/icon1.png",
      },
      {
        Service: "Go-To-Market",
        img: "/Images/servicePage/ourService/marketing/icon2.png",
      },
      {
        Service: "Email Marketing",
        img: "/Images/servicePage/ourService/marketing/icon3.png",
      },
      {
        Service: "Social Media Marketing",
        img: "/Images/servicePage/ourService/marketing/icon4.png",
      },
      {
        Service: "Influencer Marketing",
        img: "/Images/servicePage/ourService/marketing/icon5.png",
      },
      {
        Service: "SMS Marketing",
        img: "/Images/servicePage/ourService/marketing/icon6.png",
      },
      {
        Service: "Video Marketing",
        img: "/Images/servicePage/ourService/marketing/icon7.png",
      },
    ],
  },
  {
    serviceHead: "Web Development",
    imageData: [
      {
        Service: "Website Development",
        img: "/Images/servicePage/ourService/webDev/icon1.png",
      },
      {
        Service: "Mobile App Development",
        img: "/Images/servicePage/ourService/webDev/icon2.png",
      },
      {
        Service: "Shopify Development",
        img: "/Images/servicePage/ourService/webDev/icon3.png",
      },
      {
        Service: "E-commerce Development",
        img: "/Images/servicePage/ourService/webDev/icon4.png",
      },
      {
        Service: "Maintenance/Update",
        img: "/Images/servicePage/ourService/webDev/icon5.png",
      },
      {
        Service: "Other",
        img: "/Images/servicePage/ourService/webDev/icon7.png",
      },
    ],
  },
  {
    serviceHead: "Advertising",
    imageData: [
      {
        Service: "PPC",
        img: "/Images/servicePage/ourService/Advertising/icon1.png",
      },
      {
        Service: "Meta",
        img: "/Images/servicePage/ourService/Advertising/icon2.png",
      },
      {
        Service: "Linkedin",
        img: "/Images/servicePage/ourService/Advertising/icon3.png",
      },
      {
        Service: "X",
        img: "/Images/servicePage/ourService/Advertising/icon4.png",
      },
      {
        Service: "Radio Advertisement",
        img: "/Images/servicePage/ourService/Advertising/icon5.png",
      },
    ],
  },
  {
    serviceHead: "Market Research",
    imageData: [
      {
        Service: "Market Analysis",
        img: "/Images/servicePage/ourService/marketRes/icon1.png",
      },
      {
        Service: "Target Audience Analysis",
        img: "/Images/servicePage/ourService/marketRes/icon2.png",
      },
      {
        Service: "Competitor Analysis",
        img: "/Images/servicePage/ourService/marketRes/icon3.png",
      },
      {
        Service: "Trend Analysis",
        img: "/Images/servicePage/ourService/marketRes/icon4.png",
      },
    ],
  },
  {
    serviceHead: "Design",
    imageData: [
      {
        Service: "Logo Design",
        img: "/Images/servicePage/ourService/design/icon1.png",
      },
      {
        Service: "UI/UX",
        img: "/Images/servicePage/ourService/design/icon2.png",
      },
      {
        Service: "Social Creatives",
        img: "/Images/servicePage/ourService/design/icon3.png",
      },
    ],
  },
];
