import { Box, Grid, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import logo_new from "./assets/logo_new.png";
import { Flex, Image } from "@chakra-ui/react";
import "./prGenerator.css";
import marketingPlan from "./assets/marketingPlan.svg";
import fill from "./assets/fill.png";
import generate from "./assets/Generate.png";
import vector from "./assets/Vector.png";
import { Try } from "@mui/icons-material";
import axios from "axios";
import { BiDislike, BiLike } from "react-icons/bi";
import { MdContentCopy } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import NavBar from "../../Components/Header/NavBar";
import Footer from "../../Components/Footer/Footer";
import ApplyBodyStylesOnLoad from "../../Components/ApplyBodyStylesOnLoad ";

const MarketingPlanGenerator = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isBig = useMediaQuery("(min-width: 769px) and (max-width: 1110px)");

  const labelStyle = {
    fontSize: isMobile ? "" : "large",
    fontWeight: "600",
    minWidth: "100%",
    lineHeight: "3rem",
  };

  const whatMarketingPlanIs = [
    {
      title: "Easy to Use",
      desc: "Input your business details, target audience, and goals, and the Al does the rest-generating a customized marketing plan in seconds. No need for expert marketing knowledge.",
    },
    {
      title: "Time-Saving",
      desc: "Instantly receive a structured marketing strategy, freeing up your time to focus on execution.",
    },

    {
      title: "Cost-Effective",
      desc: "Compared to hiring marketing consultants, the Al Marketing Plan Generator is a budget-friendly, free solution that delivers high-quality results.",
    },

    {
      title: "Data-Driven Recommendations",
      desc: "Get SEO suggestions, content strategies, and advertising advice based on the latest marketing trends and data.",
    },

    {
      title: "Customizable Output ",
      desc: "Edit and adjust the generated plan to align perfectly with your brand voice, goals, and preferences.",
    },
  ];

  const channels = [
    "Social Media",
    "Email Marketing",
    "Content Marketing",
    "Pay-Per-Click (PPC) Advertising",
    "Affiliate Marketing",
    "other",
  ];
  const goals = [
    "Increase Sales",
    "Brand Awareness",
    "Lead Generation",
    "Customer Engagement",
  ];

  const [formData, setFormData] = useState({
    companyName: "",
    companyDesc: "",
    goals: "",
    targetAudience: "",
    channels: "",
  });
  const [loading, setLoading] = useState(false);
  const [fetchedMarketingHeadings, setFetchedMarketingHeadings] = useState("");
  const [fetchedMarketingData, setFetchedMarketingData] = useState("");
  const [showCopied, setShowCopied] = useState(false);

  const [displaying, setDisplaying] = useState(true);
  console.log(formData);

  const handleFormData = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  // const cleanResponseData = (data) => {
  //   // Replace asterisks with an empty string
  //   return data.replace(/\*/g, "").replace(/#/g, "\n");
  // };
  const cleanResponseData = (data) => {
    // Check if data is an object and has the expected structure
    if (
      data &&
      data.parts &&
      data.parts.length > 0 &&
      typeof data.parts[0].text === "string"
    ) {
      // Extract the text from the first part
      const text = data.parts[0].text;
      // Clean the text
      return text.replace(/\*/g, "").replace(/#/g, "\n");
    } else {
      console.warn("Unexpected data format:", data);
      return ""; // or handle it in a way that makes sense for your application
    }
  };

  const parseResponseData = (data) => {
    const sections = data.split("\n\n");
    const executiveSummary = sections.find((section) =>
      section.startsWith("**1. Executive Summary**")
    );
    return { executiveSummary };
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(fetchedMarketingData)
      .then(() => {
        setShowCopied(true);
        setTimeout(() => {
          setShowCopied(false);
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy text to clipboard", error);
      });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setDisplaying(false);
    setLoading(true);
    try {
      const response = await axios.post(
        "https://bizowl-marketing-tool-backend.onrender.com/api/generate-strategy",
        // "https://bizowl-marketing-tool-backend.vercel.app/api/generate-strategy",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "https://www.bizzowl.com",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Content-Type, Authorization",
          },
          withCredentials: true,
        }
      );

      console.log("Response data:", response.data);

      // Clean the response data
      const cleanedData = cleanResponseData(response.data.response);

      // const sections = response.data.response.parts[0].text.split("\n\n**");

      // const section1 = sections[1].split("\n\n**")[0];
      // const section2 = sections[2].split("\n\n**")[0];
      // const section3 = sections[3].split("\n\n**")[0];
      // const section4 = sections[4].split("\n\n**")[0];
      // const section5 = sections[5].split("\n\n**")[0];

      // console.log("Section 1: Executive Summary");
      // console.log(section1);

      // console.log("\nSection 2: Customer Personas");
      // console.log(section2);

      // console.log("\nSection 3: Content Strategy");
      // console.log(section3);

      // console.log("\nSection 4: Channel Strategy");
      // console.log(section4);

      // console.log("\nSection 5: SEO & Paid Ad Strategy");
      // console.log(section5);

      const responseText = response.data.response.parts[0].text;

      const sections = responseText.split("\n\n**");

      console.log("Number of sections found:", sections.length);

      function extractSectionContent(section) {
        const lines = section.split("\n");
        const titleLine = lines.shift().trim();

        // Remove any empty lines at the beginning
        while (lines.length > 0 && lines[0].trim() === "") {
          lines.shift();
        }

        return {
          title: titleLine.replace(/^\d+\.\s*/, ""), // Remove numbering from title
          content: lines.join("\n").trim(),
        };
      }

      const extractedSections = sections.slice(1).map(extractSectionContent); // Skip the first element which is likely empty

      console.log("Extracted Sections:");
      extractedSections.forEach((section, index) => {
        console.log(`\nSection ${index + 1}: ${section.title}`);
        console.log(section.content);
      });

      setFetchedMarketingHeadings(
        extractedSections.map((heading) => heading.title)
      );

      // Instead of passing an object, pass an array of strings
      setFetchedMarketingData(
        extractedSections.map((section) => section.content)
      );

      // setFetchedMarketingData(section1);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Error response:", error.response.data);
      }
      setLoading(false);
    }
  };

  function DisplaySection({ index }) {
    const headings = fetchedMarketingHeadings;
    const sections = fetchedMarketingData;
    const section = sections[index];

    // Check if the section exists
    if (!section) {
      return;
    }

    // Function to remove markdown formatting
    function removeMarkdownFormatting(text) {
      return text
        .replace(/(\*\*|\*\s*\*|^\s*\*\s*$)/g, "")
        .replace(/^#\s*/, "") // Remove markdown heading formatting
        .trim(); // Remove leading/trailing whitespace
    }

    // Process the content
    let processedContent;
    if (index === 3 || index === 4) {
      // Customer Personas sections
      const lines = sections[index].split("\n");
      processedContent = lines.map((line) => {
        if (line.trim().startsWith("*")) {
          return `\n${removeMarkdownFormatting(line).replace(/^\*+/, "")}`;
        }
        return line;
      });
    } else {
      processedContent = removeMarkdownFormatting(sections[index]);
    }

    // Determine if we should display a heading
    const showHeading = index === 3 || index === 4; // Only show heading for Customer Personas

    return (
      <div>
        {showHeading && <h2>{headings[index]}</h2>}
        <p style={{ whiteSpace: "pre-wrap" }}>{processedContent}</p>
      </div>
    );
  }

  return (
    <div
      className="flex flex-col h-screen overflow-auto font-cabin w-full relative"
      style={{
        minHeight: "100vh",
      }}
    >
      <ApplyBodyStylesOnLoad />
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>
          AI Marketing Plan Generator & Tools | Build a Custom Marketing Plan |
          Bizowl
        </title>
        <meta
          name="description"
          content="Generate a strategic marketing plan with Bizowl's AI Marketing Plan Generator. Discover powerful AI marketing tools and solutions to enhance your business marketing strategy."
        />
        <meta
          name="keywords"
          content="Marketing Tool, Marketing Plan Generator, AI Marketing Tools, AI Tools for Marketing, AI Marketing Plan Generator, Marketing Strategy Tool, AI Tools for Startups, Create a Marketing Plan, Build a Marketing Plan with AI, Startup Marketing Tool, Marketing Automation"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Bizowl" />
        <meta http-equiv="Content-Language" content="en" />
        <link
          rel="canonical"
          href="https://www.bizzowl.com/ai-startup-tools/ai-marketing-plan-generator"
        />
        <meta
          property="og:title"
          content="AI Marketing Plan Generator & Tools | Build a Custom Marketing Plan | Bizowl"
        />
        <meta
          property="og:description"
          content="Bizowl offers an AI Marketing Plan Generator and a range of AI marketing tools to help businesses create data-driven marketing strategies. Build your custom marketing plan now!"
        />
        <meta
          property="og:image"
          content="https://www.bizzowl.com/path-to-image.png"
        />
        <meta
          property="og:url"
          content="https://www.bizzowl.com/ai-startup-tools/ai-marketing-plan-generator"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="AI Marketing Plan Generator & Tools | Build a Custom Marketing Plan | Bizowl"
        />
        <meta
          name="twitter:description"
          content="Easily create a marketing plan using Bizowl's AI-powered tools. Get actionable insights and AI marketing tools for your business strategy."
        />
        <meta
          name="twitter:image"
          content="https://www.bizzowl.com/path-to-image.png"
        />

        <script type="application/ld+json">
          {`
  "@context": "https://schema.org",
  "@type": "SoftwareApplication",
  "name": "AI Marketing Plan Generator",
  "operatingSystem": "Web",
  "applicationCategory": "BusinessApplication",
  "description": "Bizowl's AI Marketing Plan Generator helps businesses create custom marketing plans with AI-powered tools. Ideal for startups and small businesses looking for strategic insights and automation in their marketing.",
  "url": "https://www.bizzowl.com/ai-startup-tools/ai-marketing-plan-generator",
  "image": "https://res.cloudinary.com/dcduojrhf/image/upload/v1727975286/y7rwniqdsepoj9vm5yq5.jpg",
  "offers": {
    "@type": "Offer",
    "price": "Free",
    "priceCurrency": "INR",
    "url": "https://www.bizzowl.com/ai-startup-tools/ai-marketing-plan-generator"
  },
  "author": {
    "@type": "Organization",
    "name": "Bizowl",
    "url": "https://www.bizzowl.com"
  },
  "applicationSubCategory": "MarketingTool",
  "softwareVersion": "1.0",
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.8",
    "reviewCount": "125"
  },
  "featureList": [
    "AI-powered marketing strategy generation",
    "Customizable marketing plans",
    "Market research integration",
    "Real-time insights for better decision making"
  ],
  "review": {
    "@type": "Review",
    "author": {
      "@type": "Person",
      "name": "Team Bizowl"
    },
    "reviewRating": {
      "@type": "Rating",
      "ratingValue": "5",
      "bestRating": "5"
    },
    "reviewBody": "Bizowl's AI Marketing Plan Generator helped me create a detailed and actionable marketing strategy for my startup in minutes!"
  },
  "potentialAction": {
    "@type": "CreateAction",
    "target": "https://www.bizzowl.com/ai-startup-tools/ai-marketing-plan-generator",
    "name": "Generate Your Marketing Plan"
  },
  "publisher": {
    "@type": "Organization",
    "name": "Bizowl",
    "url": "https://www.bizzowl.com",
    "logo": {
      "@type": "ImageObject",
      "url": "https://www.bizzowl.com/Images/logos/logo-of-BIZOWL--Business-Services.png"
    }
  }`}
        </script>
      </Helmet>
      {/* Navbar */}
      {/* <nav className={`sticky top-0  shadow-none  bg-[#EFF2F9] ${isMobile?"h-[3rem] z-200 pl-5 pb-[2rem] pt-5":"h-[5rem] z-[2] pl-5 pb-[3rem] pt-5"}`}>
      <img src={logo_new} alt="Logo" className={`cursor-pointer ${isMobile?"h-[30px] ":""}`} onClick={()=>navigate("/")}/>
    </nav> */}
      {!isMobile && <NavBar />}

      <div
        className={`${
          isMobile ? "flex flex-col overflow-y-auto" : "flex flex-row "
        } w-[100%] gap-8 bg-[#f7fcfb]`}
        style={{
          background: `linear-gradient(360deg, #f9fdfc 30%, #E4F0F7 90%)`,
        }}
      >
        {isMobile && <NavBar />}
        {/* left side scrollable */}
        <div
          className={`flex flex-col ${
            isMobile ? "w-[100%] " : " w-[50%]"
          } overscroll-y-auto mt-12 mb-12 `}
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: isMobile ? (displaying ? "flex" : "none") : "",
          }}
        >
          <div className="w-[90%]">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: isMobile ? "" : "100%",
              }}
            >
              <p className={`${isMobile ? "" : ""} text-[gray]`}>
                Marketing Plan Builder{" "}
              </p>
              <div
                className={`w-[80%] mt-2  ${
                  isMobile ? "text-2xl" : isBig ? "text-3xl" : "text-3xl"
                }`}
              >
                <p>
                  Free{" "}
                  <span className={`text-[#407BFF]`}>
                    Ai Marketing Plan Generator:
                  </span>{" "}
                  Create
                </p>
                <p>Your Custom Strategy Instantly</p>
              </div>
              <div className="h-[1px] w-[80%] mx-auto bg-[#d7ddde] mt-7"></div>
              <div
                className={`flex justify-center items-center bg-[#D7E9FD] rounded-[2rem] mt-7 ${
                  isMobile ? "w-[80%]" : ""
                } min-w-[80%]`}
              >
                <img
                  src={marketingPlan}
                  className={`${isMobile ? "w-[150px]" : ""}`}
                />
              </div>

              <p
                className={` ${
                  isMobile
                    ? "mt-8 text-xl font-[200]"
                    : isBig
                    ? "text-2xl mt-8"
                    : "mt-16 text-3xl "
                }`}
              >
                Ai Marketing Plan Generator{" "}
              </p>
              <p
                className={`${
                  isMobile
                    ? "mt-3 text-md text-justify opacity-90 font-[100]"
                    : isBig
                    ? "mt-3 opacity-90 font-[100]"
                    : "mt-3 font-[100]"
                }`}
              >
                Bizowl's Al Marketing Plan Generator is a powerful tool designed
                to create tailored marketing strategies for businesses and
                startups. Using advanced Al technology, this free tool
                simplifies marketing plan creation, delivering results in
                minutes instead of days. Whether you're an entrepreneur, startup
                founder, or marketing professional, our Al ensures your strategy
                is well-researched, effective, and ready for implementation.
              </p>
            </div>

            <form
              onSubmit={submitHandler}
              className="flex flex-col mt-4 min-w-[100%]"
              style={{
                alignItems: "start",
              }}
            >
              <label htmlFor="companyName" style={labelStyle}>
                <p>
                  <span className={`text-[#407BFF]`}>Company</span> Name
                </p>
                <br />
                <input
                  className="bg-white rounded-md w-full p-3 h-14 font-[100] text-[grey]"
                  name="companyName"
                  id="companyName"
                  placeholder="Startup Name"
                  onChange={handleFormData}
                />
              </label>

              <label htmlFor="companyDesc" style={labelStyle}>
                <p>
                  <span className={`text-[#407BFF]`}>Company</span> Description
                </p>
                <br />
                <textarea
                  name="companyDesc"
                  id="companyDesc"
                  onChange={handleFormData}
                  className={`w-full text-[grey] rounded-md h-[200px] p-3 leading-6 ${
                    isMobile ? "" : "font-[100]"
                  }`}
                  placeholder="Details about your, what do you do, problem and solution you are working around."
                ></textarea>
              </label>

              <label htmlFor="goals" style={labelStyle}>
                <p>
                  <span className={`text-[#407BFF]`}>Primary Goal</span> of the
                  Business
                </p>
                <br />
                <select
                  className="bg-white rounded-md w-full p-3 h-14 font-[100] text-[gray]"
                  name="goals"
                  id="goals"
                  defaultValue=""
                  onChange={handleFormData}
                >
                  <option disabled value="">
                    Increase sales, brand awareness
                  </option>
                  {goals.map((goal, index) => (
                    <option key={index} value={goal}>
                      {goal}
                    </option>
                  ))}
                </select>
              </label>

              <label htmlFor="targetAudience" style={labelStyle}>
                <p>
                  <span className={`text-[#407BFF]`}>Target</span> Audience
                </p>
                <br />
                <input
                  type="text"
                  name="targetAudience"
                  id="targetAudience"
                  onChange={handleFormData}
                  className="w-full rounded-md p-3 h-14 font-[100] text-[gray] "
                  placeholder="Who is your ideal customer? (age, gender, location, etc.)"
                />
              </label>

              <label htmlFor="channels" style={labelStyle}>
                Marketing Channels
                <br />
                <select
                  className="bg-white rounded-md w-full p-3 h-14 font-[100] text-[gray]"
                  name="channels"
                  id="channels"
                  defaultValue=""
                  onChange={handleFormData}
                >
                  <option disabled value="">
                    Social Media, Email, Organic, Paid, Etc
                  </option>
                  {channels.map((channel, index) => (
                    <option key={index} value={channel}>
                      {channel}
                    </option>
                  ))}
                </select>
              </label>
              <br />
              <button className="bg-[#407BFF] text-white font-light rounded-lg mt-12">
                Generate Marketing Plan
              </button>
            </form>

            <div
              className="flex flex-col mt-20"
              style={{
                alignItems: "start",
                textAlign: "left",
                gap: "1rem",
              }}
            >
              <p
                className={`${
                  isMobile ? "text-2xl" : isBig ? "text-2xl" : " text-3xl"
                }`}
              >
                What is AI Marketing Plan Generator?{" "}
              </p>
              <p className={`text-[#5a6569] ${isMobile ? "" : "text-lg"}`}>
                The Al Marketing Plan Generator is an advanced tool that uses
                artificial intelligence to create a fully tailored marketing
                strategy for your business. Designed to streamline the marketing
                planning process, this tool eliminates the guesswork and
                provides data-driven recommendations across various marketing
                channels. Here's why this Al-driven tool is a game-changer for
                businesses of all sizes:
              </p>

              {whatMarketingPlanIs.map((item, index) => (
                <p className={`text-[#5a6569] ${isMobile ? "" : "text-lg"}`}>
                  <span className="font-bold text-[#407BFF]">{item.title}</span>
                  - {item.desc}
                </p>
              ))}

              <div className="h-[1px] w-[80%] mx-auto bg-[#d7ddde] mt-7"></div>

              <div className="flex-col mt-12">
                <p
                  className={`${
                    isMobile ? "text-2xl" : isBig ? "text-2xl" : " text-3xl"
                  }`}
                >
                  Who can benefit from using an AI Marketing Plan Generator?
                </p>
                <div
                  className="flex flex-col justify-start items-start gap-4 mt-4"
                  style={{
                    alignItems: "start",
                  }}
                >
                  <div className="flex gap-4">
                    <div
                      className={`flex  justify-center items-center rounded-full ${
                        isMobile
                          ? "w-[25px] h-[25px]"
                          : "w-[33px] h-[33px] rounded-full "
                      } bg-[#D7E9FD]`}
                    >
                      <img
                        src={vector}
                        className={`${isMobile ? "w-[15px] h-[15px]" : ""}`}
                      />
                    </div>
                    <p>Startup Founders</p>
                  </div>

                  <div className="flex gap-4">
                    <div
                      className={`flex  justify-center items-center rounded-full ${
                        isMobile
                          ? "w-[25px] h-[25px]"
                          : "w-[33px] h-[33px] rounded-full "
                      } bg-[#D7E9FD]`}
                    >
                      <img
                        src={vector}
                        className={`${isMobile ? "w-[15px] h-[15px]" : ""}`}
                      />
                    </div>
                    <p>Marketing Team</p>
                  </div>

                  <div className="flex gap-4">
                    <div
                      className={`flex  justify-center items-center rounded-full ${
                        isMobile
                          ? "w-[25px] h-[25px]"
                          : "w-[33px] h-[33px] rounded-full "
                      } bg-[#D7E9FD]`}
                    >
                      <img
                        src={vector}
                        className={`${isMobile ? "w-[15px] h-[15px]" : ""}`}
                      />
                    </div>
                    <p>Freelancers</p>
                  </div>
                  <div className="flex gap-4">
                    <div
                      className={`flex  justify-center items-center rounded-full ${
                        isMobile
                          ? "w-[25px] h-[25px]"
                          : "w-[33px] h-[33px] rounded-full "
                      } bg-[#D7E9FD]`}
                    >
                      <img
                        src={vector}
                        className={`${isMobile ? "w-[15px] h-[15px]" : ""}`}
                      />
                    </div>
                    <p>Small and Medium Business Owners</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="h-[1px] w-[80%] mx-auto bg-[#d7ddde] mt-7"></div>
            <div className="mt-12 text-left">
              <p
                className={`${
                  isMobile ? "text-2xl" : isBig ? "text-2xl" : " text-3xl"
                }`}
              >
                How to use this AI Marketing Plan Generator?{" "}
              </p>
              <div className={`flex mt-4 gap-10 flex-wrap justify-center`}>
                <div
                  className={`bg-white transform transition-transform hover:scale-110 flex flex-col rounded-md justify-start items-center text-center ${
                    isMobile
                      ? "min-w-[300px] min-h-[280px] max-w-[300px] max-h-[280px]"
                      : "min-w-[317px] min-h-[297px] max-w-[317px] max-h-[320px]  p-7"
                  } gap-3 shadow-md`}
                >
                  <div
                    className={`flex flex-col ${
                      isMobile ? "w-[95%] gap-2 p-3" : "gap-3"
                    }`}
                  >
                    <p className={`${isMobile ? "" : "text-[20px]"}`}>
                      Fill Details
                    </p>
                    <Box>
                      <img src={fill} />
                    </Box>
                    <p className={`${isMobile ? "" : "text-[16px]"}`}>
                      Enter key information about your business, including your
                      goals, target audience, and marketing channels. The more
                      detailed your input, the better the results.
                    </p>
                  </div>
                </div>

                <div
                  className={`bg-white transform transition-transform hover:scale-110 flex flex-col rounded-md justify-start items-center text-center ${
                    isMobile
                      ? "min-w-[300px] min-h-[280px] max-w-[300px] max-h-[280px]"
                      : "min-w-[317px] min-h-[297px] max-w-[317px] max-h-[320px] p-7 gap-3"
                  } shadow-md`}
                >
                  <div
                    className={` flex flex-col ${
                      isMobile ? "w-[95%] gap-2 p-3" : "gap-3"
                    }`}
                  >
                    <p className={`${isMobile ? "" : "text-[20px]"}`}>
                      Generate
                    </p>
                    <Box>
                      <img src={generate} />
                    </Box>
                    <p className={`${isMobile ? "" : "text-[16px]"}`}>
                      In a matter of seconds, our Al will produce a
                      comprehensive marketing plan based on your input. The plan
                      will include content suggestions, channel strategies, SEO
                      insights, and advertising recommendations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-20">
              <div className="h-[1px] w-[80%] mx-auto bg-[#d7ddde] mt-7"></div>
              <div className="mt-12 text-left">
                <p
                  className={`${
                    isMobile ? "text-2xl" : isBig ? "text-2xl" : " text-3xl"
                  }`}
                >
                  Why Choose Bizowl's AI Marketing Plan Generator
                </p>
              </div>
              <div className="mt-7 text-left">
                <p>
                  Bizowl's Al Marketing Plan Generator not only provides you
                  with a high-quality, tailored marketing strategy, but it also
                  helps you achieve better marketing outcomes. With its
                  user-friendly interface, detailed suggestions, and
                  customizable features, this tool ensures that your business
                  reaches its marketing goals efficiently.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* right side  */}

        <div
          className={`   ${
            isMobile
              ? ` mt-8 mb-16 mx-auto items-start hidden justify-center ${
                  !displaying && "flex min-w-[300px]"
                }`
              : "top-[7rem]  h-[100vh] w-full max-w-[547px]  left-[50%] fixed"
          }`}
          style={{
            position: !isMobile && "fixed", // Use relative position to ensure container appears above footer
            zIndex: 0, // Add a z-index to ensure container appears below footer
          }}
        >
          <div
            className={`bg-white rounded-lg flex flex-col  ${
              isMobile
                ? "w-[100%] min-w-[90%] min-h-[50%]"
                : "max-w-[547px] max-h-[597px]  min-h-[597px] items-center   "
            } shadow-md`}
            style={{
              width: isBig && "70%",
              width: isMobile && "350px",
              maxWidth: isMobile ? "100%" : "547px", //
            }}
          >
            <div className="w-[90%] mt-8" style={{}}>
              <div
                className="flex flex-col gap-3 "
                style={{
                  alignItems: "start",

                  justifyContent: "space-between",
                  paddingBottom: isMobile ? "1rem" : "",
                }}
              >
                <p className="text-xl font-semibold">Marketing Plan</p>
                <div className="h-[1px] w-[100%] mx-auto bg-[#d7ddde] "></div>
                <div
                  className=" max-h-[400px] min-h-[400px] w-full hide-scrollbar"
                  style={{ overflowY: "auto", overflowX: "hidden" }}
                >
                  <p
                    className={`text-left ${
                      isMobile ? "text-md font-[200]" : "text-lg font-light"
                    } `}
                  >
                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",

                          position: "relative",
                          paddingTop: isMobile ? "8rem" : "8rem",
                        }}
                      >
                        <iframe
                          src="https://giphy.com/embed/06aA0f1QpAshEfFbFH"
                          width="100%"
                          height="100%"
                          // style={{ position: 'relative' }}
                          className="giphy-embed mx-auto"
                          allowFullScreen
                          title="Giphy"
                        ></iframe>
                      </div>
                    ) : (
                      <>
                        <div>
                          <h3 className="text-2xl">
                            <span className="text-[#407BFF]">Executive</span>{" "}
                            Summary
                          </h3>
                          <DisplaySection index={0} />
                        </div>
                        <br />
                        <div>
                          <h3 className="text-2xl">
                            <span className="text-[#407BFF]">Key</span> Business
                            Goals
                          </h3>
                          <DisplaySection index={1} />
                        </div>
                        <br />
                        <div>
                          <h3 className="text-2xl">
                            <span className="text-[#407BFF]">Customer</span>{" "}
                            Personas
                          </h3>
                          <DisplaySection index={3} />
                          <DisplaySection index={4} />
                        </div>
                        <br />
                        <div>
                          <h3 className="text-2xl">
                            <span className="text-[#407BFF]">Content</span>{" "}
                            Strategy
                          </h3>
                          <DisplaySection index={5} />
                          <br />
                          {<DisplaySection index={6} /> ? (
                            <p style={{ fontWeight: "bold" }}>
                              Content Ideas:{" "}
                            </p>
                          ) : (
                            ""
                          )}
                          <DisplaySection index={6} />
                        </div>
                        <br />
                        <div>
                          <h3 className="text-2xl">
                            <span className="text-[#407BFF]">Channel</span>{" "}
                            Strategy
                          </h3>
                          <DisplaySection index={8} />
                          <DisplaySection index={9} />
                          <DisplaySection index={10} />
                        </div>
                        <br />
                        <div>
                          <h3 className="text-2xl">
                            <span className="text-[#407BFF]">SEO &</span> Paid
                            Strategy
                          </h3>
                          <br />
                          {<DisplaySection index={12} /> ? (
                            <p style={{ fontWeight: "bold" }}>SEO Strategy</p>
                          ) : (
                            ""
                          )}
                          <DisplaySection index={12} />
                          <br />
                          {<DisplaySection index={13} /> ? (
                            <p style={{ fontWeight: "bold" }}>
                              Paid Advertising Strategy
                            </p>
                          ) : (
                            ""
                          )}
                          <DisplaySection index={13} />
                        </div>
                        <br />
                        <br />
                        <div>
                          <h3 className="text-2xl">
                            <span className="text-[#407BFF]">Next</span> Steps
                          </h3>
                          {<DisplaySection index={14} /> ? (
                            <p>
                              To get the most out of your marketing strategy and
                              ensure it aligns with your business objectives, we
                              recommend booking a consultation with Bizowl for
                              personalized guidance. Our experts can help
                              optimize every aspect of your plan for better
                              results.
                              <br />
                              Book your consultation here:{" "}
                              <a href="/">
                                Startup Consultation Service by Bizowl
                              </a>
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <br />
                      </>
                    )}
                  </p>
                </div>

                <div
                  className={`flex justify-between w-[100%] ${
                    isMobile ? "" : ""
                  }`}
                >
                  <button
                    disabled={fetchedMarketingData === undefined}
                    className="bg-[#407BFF] text-white transform transition-transform hover:scale-110"
                    onClick={submitHandler}
                  >
                    Need Help?
                  </button>
                  <Flex gap="1rem">
                    <div className="relative">
                      <MdContentCopy
                        size="2rem"
                        cursor="pointer"
                        className=" transform transition-transform hover:scale-110"
                        onClick={handleCopy}
                      />
                      {showCopied && (
                        <div
                          className={`${
                            isMobile
                              ? "absolute -top-10 left-7 "
                              : "transform  -top-10 left-7  absolute"
                          } text-white text-sm px-4 py-2 rounded-tr-md rounded-br-md rounded-t-md bg-[#757880] `}
                        >
                          Copied!
                        </div>
                      )}
                    </div>
                    <BiLike
                      size="2rem"
                      cursor="pointer"
                      className="transform transition-transform hover:scale-110"
                    />
                    <BiDislike
                      size="2rem"
                      cursor="pointer"
                      className="transform transition-transform hover:scale-110"
                    />
                  </Flex>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isMobile && (
        <div
          className="absolute bottom-0 w-full"
          style={{
            position: "relative",
            zIndex: 1,
            padding: "20px",
          }}
        ></div>
      )}
    </div>
  );
};

export default MarketingPlanGenerator;
