import React from "react";
import { useMediaQuery } from "@mui/material";

const Features = ({ featuresOverview }) => {
  const isMobile = useMediaQuery("(max-width:768px)");
  // const benefitsData= [
  return (
    <div className="flex flex-col mt-14 !items-start w-[100%] md:w-[68%] ">
      <h3 className="font-cabin font-[500] text-xl md:text-3xl">
        Features of Idea Validation Service{" "}
      </h3>
      <div className="w-[100%] h-[1px] bg-[#263238] opacity-20 mt-4 mb-4"></div>

      <div className="grid md:!grid-cols-2 md:grid-rows-5 !gap-x-20 !gap-y-7 w-[100%] text-left font-cabin">
        {featuresOverview?.slice(0, isMobile ? 5 : featuresOverview.length)
          .map((data, index) => (
            <div key={index} className="flex gap-4">
              <img
                src={data.icon}
                alt={`Icon ${index}`}
                className="h-[30px] w-[30px] md:w-[45px] md:h-[45px] "
              />
              <p className="font-medium text-[15px] md:text-xl">{data.title}</p>
            </div>
          ))}
      </div>

      <div className="mx-auto mt-4 md:hidden">
        <button className="!bg-transparent border-[4px] border-[#1C6ED0] text-[15px] font-cabin rounded-[10px] px-4">
          View all Features
        </button>
      </div>
    </div>
  );
};

export default Features;
